import { ChangeEvent, ReactNode, useState } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { requestEventTrigger } from "../../../apiCalls/event-trigger";

export default function EventTrigger() {
  const [trigger, setTrigger] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const onEventTrigger = (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => {
    setTrigger(event.target.value);
    // reset the message after switching the trigger
    setMessage("");
  };

  const onMessageContent = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const reset = () => {
    setTrigger("");
    setMessage("");
  };

  const onSubmitClick = async () => {
    try {
      await requestEventTrigger({
        action: trigger,
        data: JSON.parse(message),
      });
      alert("The event has been submitted successfully");
      reset();
    } catch (e) {
      alert("something wrong, please double check the message content!");
    }
  };

  const RenderSelector = (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div" sx={{ paddingBottom: 2 }}>
          Event Trigger
        </Typography>
        <FormControl variant="outlined" style={{ width: "300px" }}>
          <InputLabel>Event Trigger</InputLabel>
          <Select
            onChange={onEventTrigger}
            label="Event Trigger"
            value={trigger}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="sisloadusers">SIS Load Users</MenuItem>
            <MenuItem value="processCSV">Process CSV</MenuItem>
            <MenuItem value="loadLeadersFromClever">
              Load Leaders From Clever
            </MenuItem>
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );

  const RenderMessage = (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div" sx={{ paddingBottom: 2 }}>
          Message
        </Typography>
        <TextField
          label="Message Content (JSON Body)"
          variant="outlined"
          value={message}
          onChange={onMessageContent}
          fullWidth
          multiline
          minRows={6}
          maxRows={12}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={onSubmitClick}
          style={{ marginTop: "20px" }}
        >
          Submit
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <>
      <div>
        {RenderSelector}
        {RenderMessage}
      </div>
    </>
  );
}
