import { ReactNode, useState } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ArchiveSingleDomain from "./ArchiveDomainSingle";
import ArchiveDomainBatch from "./ArchiveDomainBatch";


export default function ArchiveDomain() {
  // single as default
  const [option, setOption] = useState<string>("single");

  const onOption = (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => {
    setOption(event.target.value);
  };


  const ArchiveDomainOptionsComponent = (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div" sx={{ paddingBottom: 2 }}>
          Domain Archiving (Temporal Based)
        </Typography>

        <FormControl variant="outlined" style={{ width: "300px" }}>
          <InputLabel>Domain Archiving Options</InputLabel>
          <Select
            onChange={onOption}
            label="Domain Archiving Options"
            value={option}
          >
            <MenuItem value="single">Single Domain</MenuItem>
            <MenuItem value="batch">Domain Batch</MenuItem>
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );

  const archivingDomainComponents: Record<string, JSX.Element> = {
    "single": <ArchiveSingleDomain />,
    "batch": <ArchiveDomainBatch />,
  };

  return (
    <>
      <div>
        {ArchiveDomainOptionsComponent}
        {archivingDomainComponents[option]}
      </div>

    </>
  );
}
