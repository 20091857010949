import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import TroubleshootingBar from './TroubleshootingBar'
import NavBar from '../../components/Authentication/Layout/NavBar'
import HighlightsBusManagement from './HighlightsBusesManagement'
import TasksManagement from './TasksManagement'
import { Route, Redirect, Switch } from 'react-router-dom'
import StatusDashboard from './StatusDashboard'
import BulkCopy from './BulkCopy/BulkCopy'
import NewTaskCopy from './NewTaskCopy/NewTaskCopy'
import UndeleteWorkspace from './UndeleteWorkspace/UndeleteWorkspace'
import UpdateUserEmail from './UpdateUserEmail/UpdateUserEmail'
import ListGroupMembers from './ListGroupMembers/ListGroupMembers'
import EventTrigger from './EventTrigger/EventTrigger'
import ArchiveDomain from './ArchiveDomain/ArchiveDomain'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  content: {
    width: '100%',
  },
}))

export default function HighlightsBuses() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <NavBar />
      <TroubleshootingBar />
      <div className={classes.content}>
        <Toolbar />
        <Switch>
          <Route path={`/internal-admin/status`} component={StatusDashboard} />
          <Route path={`/internal-admin/buses`} component={HighlightsBusManagement} />
          <Route path={`/internal-admin/tasks`} component={TasksManagement} />
          <Route path={`/internal-admin/bulk-copy`} component={BulkCopy} />
          <Route path={'/internal-admin/new-task-copy'} component={NewTaskCopy} />
          <Route path={'/internal-admin/undelete-workspace'} component={UndeleteWorkspace} />
          <Route path={'/internal-admin/update-user-email'} component={UpdateUserEmail} />
          <Route path={'/internal-admin/list-group-members'} component={ListGroupMembers} />
          <Route path={'/internal-admin/event-trigger'} component={EventTrigger} />
          <Route path={'/internal-admin/archive-domain'} component={ArchiveDomain} />
          <Redirect from={'/internal-admin'} to={`/internal-admin/status`} />
        </Switch>
      </div>
    </div>
  )
}
