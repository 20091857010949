import { useState } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { requestBatchArchiveDomain } from "../../../apiCalls/archive-domain";


const maxFileSizeMB = 2
const maxFileSizeByte = maxFileSizeMB * 1024 * 1024

export default function ArchiveDomainBatch() {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!(event.target.files && event.target.files.length > 0)) {
      return
    }

    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
    if (selectedFile.size > maxFileSizeByte) {
      setError(`File size exceeds the limit of ${maxFileSizeMB} MB.`);
      setFile(null);
      setFileName("");
      return;
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError("Please select a file before uploading.");
      return;
    }

    setIsUploading(true);
    setError(null);

    const formData = new FormData();
    formData.append("domain_archiving_file", file);

    try {
      await requestBatchArchiveDomain(formData);
      alert("File uploaded successfully!");
    } catch (err: any) {
      alert("something wrong, please double check the file!");
      setError("Failed to upload the file. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };
  
  const RenderUpload = (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div" sx={{ paddingBottom: 2 }}>
        Domain Batch Archiving
        </Typography>
      </CardContent>

      <CardContent>
      <TextField
        type="file"
        onChange={handleFileChange}
        InputLabelProps={{ shrink: true }}
        inputProps={{ accept: "text/csv" }}
        style={{ marginBottom: "16px" }}
      />
      <div>
        {fileName && <p>Selected File: {fileName}</p>}
        {isUploading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={!file}
          >
            Upload
          </Button>
        )}
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      </CardContent>
    </Card>
  );

  return (
    <>
      <div>{RenderUpload}</div>
    </>
  );
}
