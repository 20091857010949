import axios from 'axios'
import _ from 'lodash'
import store from '../state/store'
import { postSendEvent } from '../apiCalls/user'
import {
  getErrorStringFromErrorOrResponse,
  checkExpiration,
  handleLocalStorage,
} from './utils'

const AUTH_ENDPOINT = {
  GET_TOKEN: `${process.env.REACT_APP_API_HAPARA_URL}/auth-service/oauth/token?grant_type=refresh_token`,
  REFRESH_TOKEN: `${process.env.REACT_APP_API_HAPARA_URL}/auth-service/oauth/token`,
  LOGOUT: `${process.env.REACT_APP_API_HAPARA_URL}/auth-service/logout`,
}
const BEARER_TOKEN_TYPE = 'Bearer'
export const JWT_BODY = {
  roles: ['teacher', 'admin'],
}
let jwtToken = null

export const isProduction = () => {
  return process.env.REACT_APP_BUILD_ENV === "production"
}

//1a: INITIAL LOG IN AND HANDLES DATA STORAGE
export const initHaparaJWT = async googleJWT => {
  store.dispatch({ type: 'APP_HJWT_PENDING' })
  try {
    const res = await getHaparaJWT(googleJWT)
    store.dispatch({ type: 'APP_HJWT_SUCCESS' })
    handleLocalStorage({ isSignedIn: true })
    jwtToken = res
  } catch (err) {
    store.dispatch({ type: 'APP_HJWT_ERROR', payload: err })
    postSendEvent({
      name: 'load-jwt-token',
      error_message: getErrorStringFromErrorOrResponse(err),
    })
  }
}

//1b: GETS HAPARA JWT & COOKIES
const getHaparaJWT = async googleJWT => {
  const url = AUTH_ENDPOINT.GET_TOKEN
  const config = {
    headers: { Authorization: `${BEARER_TOKEN_TYPE} ${googleJWT}` },
    withCredentials: true,
  }
  const res = await axios.post(url, JWT_BODY, config)
  const jwtToken = {
    access_token: _.get(res, 'data.access_token'),
    at_expires: _.get(res, 'data.at_expires'),
    token_type: BEARER_TOKEN_TYPE,
  }
  return jwtToken
}

//2a:CHECKS EXPIRATION OF TOKENS & REFRESHES IF NECESSARY
export const getToken = async () => {
  const isAccessTokenValid = checkExpiration(jwtToken?.at_expires)
  const isRefreshTokenValid = checkExpiration()

  if (jwtToken && isAccessTokenValid) {
    return jwtToken
  } else if (isRefreshTokenValid) {
    try {
      const res = await refreshHaparaJWT()
      store.dispatch({ type: 'APP_HJWT_SUCCESS', payload: res })
      handleLocalStorage({ isSignedIn: true, session: res })
      jwtToken = res
      return jwtToken
    } catch (err) {
      store.dispatch({ type: 'APP_HJWT_ERROR', payload: err })
      postSendEvent({
        name: 'load-jwt-token',
        error_message: getErrorStringFromErrorOrResponse(err),
      })
    }
  } else {
    logOut()
  }
}

//2b: REFRESHES TOKEN
const refreshHaparaJWT = async () => {
  const url = AUTH_ENDPOINT.REFRESH_TOKEN
  const config = {
    withCredentials: true,
  }
  const res = await axios.post(url, JWT_BODY, config)
  const jwtToken = {
    access_token: _.get(res, 'data.access_token'),
    at_expires: _.get(res, 'data.at_expires'),
    token_type: BEARER_TOKEN_TYPE,
  }
  return jwtToken
}

//3. LOGS OUT THE USER AND CLEARS SESSION DETAILS
export const logOut = () => {
  const url = AUTH_ENDPOINT.LOGOUT
  const config = { withCredentials: true }

  axios
    .post(url, JWT_BODY, config)
    .catch((err) => {
      postSendEvent({
        name: 'expired-jwt-token',
        error_message: getErrorStringFromErrorOrResponse(err),
      })
    })
    .finally(() => {
      store.dispatch({ type: 'APP_HJWT_SIGNOUT' })
      handleLocalStorage({ isSignedIn: false })
      jwtToken = null
      if (window.location.pathname !== '/admin') {
        window.location.replace('/admin')
      }
    })
}
