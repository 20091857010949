import { ChangeEvent, useState } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { isProduction } from "../../../auth/handleJWT";
import { requestArchiveDomain } from "../../../apiCalls/archive-domain";

export default function ArchiveSingleDomain() {
  const [domain, setDomain] = useState<string>("");
  const [yearLabel, setYearLabel] = useState<string>("");

  const onDomain = (event: ChangeEvent<HTMLInputElement>) => {
    setDomain(event.target.value);
  };

  const onYearLabel = (event: ChangeEvent<HTMLInputElement>) => {
    setYearLabel(event.target.value);
  };

  const reset = () => {
    setDomain("");
    setYearLabel("");
  };

  const onSubmitClick = async () => {
    try {
      const resp = await requestArchiveDomain({
        domain_id: domain,
        year_label: yearLabel,
      });
      const namespace = isProduction() ? "hapara-provisioning-prod.p0vbr" : "hapara-provisioning-test.p0vbr"
      const url = `https://cloud.temporal.io/namespaces/${namespace}/workflows?query=%60RunId%60%3D"${resp.run_id}"`;
      alert(
        `The domain archive command has been submitted successfully, go to Temporal to see details.`
      );
      // open new tab to Temporal Cloud
      window.open(url);
      reset();
    } catch (e) {
      alert("something wrong, please double check the variables!");
    }
  };

  const RenderForm = (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div" sx={{ paddingBottom: 2 }}>
          Single Domain Archiving
        </Typography>
      </CardContent>
      <CardContent>
        <FormControl variant="outlined">
          <TextField
            label="Domain"
            variant="outlined"
            value={domain}
            onChange={onDomain}
          />
        </FormControl>
      </CardContent>

      <CardContent>
        <FormControl variant="outlined">
          <TextField
            label="Year Label"
            variant="outlined"
            value={yearLabel}
            onChange={onYearLabel}
          />
        </FormControl>
      </CardContent>

      <CardContent>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmitClick}
          style={{ marginTop: "20px" }}
        >
          Submit
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <>
      <div>{RenderForm}</div>

    </>
  );
}
