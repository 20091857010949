import { isProduction } from "../../../auth/handleJWT";

export default function bulkCopy () {
  const namespace = isProduction() ? "content-management-prod.p0vbr" : "content-management-test.p0vbr"
  /* Open a new tab to Temporal Cloud for the workflow status */
  window.open(`https://cloud.temporal.io/namespaces/${namespace}/workflows?query=%60WorkflowType%60%3D%22CopyCollectionWorkflow%22`);
  
  return (
    <>
      <p>Please view the workflow status in the newly opened tab!</p>
    </>
  )
}
