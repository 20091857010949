import React, { useState } from 'react'
import SideBar from '../../components/Authentication/Layout/SideBar'
import { useParams } from 'react-router-dom'
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus'
import ListIcon from '@material-ui/icons/List'
import MoreIcon from '@material-ui/icons/More'
import LaunchIcon from '@material-ui/icons/Launch';
import ArchiveIcon from '@material-ui/icons/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'

const items = [
  {
    title: 'Status Dashboard',
    path: '/internal-admin/status',
    id: 'status',
    icon: <DirectionsBusIcon />,
  },
  {
    title: 'Highlights Buses',
    path: '/internal-admin/buses',
    id: 'buses',
    icon: <DirectionsBusIcon />,
  },
  {
    title: 'Tasks',
    path: '/internal-admin/tasks',
    id: 'tasks',
    icon: <ListIcon />,
  },
  {
    title: 'New Bulk Copy',
    path: '/internal-admin/new-task-copy',
    id: 'newbulkcopy',
    icon: <FileCopyIcon />,
  },
  {
    title: 'Bulk Copy Worflow',
    path: '/internal-admin/bulk-copy',
    id: 'bulkcopyworkflow',
    icon: <MoreIcon />,
  },
  {
    title: 'Update User Email',
    path: '/internal-admin/update-user-email',
    id: 'updateuseremail',
    icon: <ContactMailIcon />,
  },
  {
    title: 'Undelete Workspace',
    path: '/internal-admin/undelete-workspace',
    id: 'undelete-workspace',
    icon: <ArrowCircleUpIcon />,
  },
  {
    title: 'List Group Members',
    path: '/internal-admin/list-group-members',
    id: 'list-group-members',
    icon: <PlaylistAddCheckIcon />,
  },
  {
    title: 'Event Trigger',
    path: '/internal-admin/event-trigger',
    id: 'event-trigger',
    icon: <LaunchIcon />,
  },
  {
    title: 'Domain Archiving (Temporal Based)',
    path: '/internal-admin/archive-domain',
    id: 'archive-domain',
    icon: <ArchiveIcon />,
  },
]

export default function TroubleshootingBar() {
  const [open, setOpen] = useState(true)
  const { id } = useParams()

  return <SideBar open={open} setOpen={setOpen} selected={id} items={items} />
}
