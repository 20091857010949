import { axiosWithInterceptors } from './axios'
import { getToken } from '../auth/handleJWT'

interface NewEventTriggerRequest {
  action: string
  data: string
}

const requestEventTrigger = async (params: NewEventTriggerRequest) => {
  const { access_token } = await getToken()
  await axiosWithInterceptors.post<NewEventTriggerRequest, null>(
    '/dataload-orchestrator-admin/tools/trigger-event',
    params,
    {
      headers: { Authorization: access_token },
    }
  )
}

export { requestEventTrigger }
export type { NewEventTriggerRequest }
