import { axiosWithInterceptors } from "./axios";
import { getToken } from "../auth/handleJWT";

interface ArchiveDomainRequest {
  domain_id: string;
  year_label: string;
}

interface ArchiveDomainResponse {
  domain_id: string;
  load_id: string;
  workflow_id: string;
  run_id: string
}


const requestArchiveDomain = async (params: ArchiveDomainRequest): Promise<ArchiveDomainResponse> => {
  const { access_token } = await getToken();
  const result = await axiosWithInterceptors.post<ArchiveDomainRequest, ArchiveDomainResponse>(
    "/dataload-orchestrator-admin/tools/archive-domain",
    params,
    {
      headers: { Authorization: access_token },
    }
  );

  return result
};

const requestBatchArchiveDomain = async (formData: FormData) => {
  const { access_token } = await getToken();
  await axiosWithInterceptors.post<ArchiveDomainRequest, null>(
    "/dataload-orchestrator-admin/tools/batch-archive-domain",
    formData,
    {
      headers: { Authorization: access_token },
    }
  );
};

export { requestArchiveDomain, requestBatchArchiveDomain };
export type { ArchiveDomainRequest };
